import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { Icon, TextInput } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';

import { EditableTextProps } from './types';
import { EditableTextActions } from './EditableTextActions';
import './EditableText.scss';

const ROOT_CLASS_NAME = 'editabletext';

function EditableText({
    mainContent,
    handleSubmit,
    handleCancel = () => {},
    index = 0,
    isEditable = false,
    isEditMode = false,
    showEditIcon = false,
    formatter = (value) => value,
    'data-testid': dataTestId,
    className
}: EditableTextProps) {
    const [isTextInput, setIsTextInput] = useState(isEditMode);
    const textInputRef = useRef<HTMLInputElement | null>(null);

    // value of 0 is not considered empty
    const isMainContentEmpty = !mainContent && mainContent !== 0;
    const conditionalClasses = {
        'editable-text--enabled': isEditable,
        '_jc-space-between': !isMainContentEmpty,
        '_jc-flex-end': isMainContentEmpty
    };
    const elemClassName = classNames(
        ROOT_CLASS_NAME,
        '_d-flex',
        className,
        conditionalClasses
    );
    const editModeClassName = classNames(
        `${ROOT_CLASS_NAME}__edit-mode`,
        '_fd-column',
        'edit-mode'
    );

    const onClickCheck = useCallback(() => {
        if (textInputRef.current?.value !== mainContent) {
            const { value } = textInputRef.current || { value: '' };
            handleSubmit?.({ index, value });
        } else {
            handleCancel();
        }
        setIsTextInput(false);
    }, [handleCancel, handleSubmit, index, mainContent]);

    const onClickCancel = useCallback(() => {
        handleCancel();
        setIsTextInput(false);
    }, [handleCancel]);

    const onClickDetail = useCallback(() => {
        if (isEditable) setIsTextInput((state) => !state);
    }, [isEditable]);

    return (
        <>
            {isTextInput && (
                <div className={editModeClassName}>
                    <TextInput
                        ref={textInputRef}
                        value={mainContent}
                        width="fullWidth"
                        variant="edit"
                        data-testid="editabletext-input"
                    />
                    <EditableTextActions
                        onClickCancel={onClickCancel}
                        onClickCheck={onClickCheck}
                    />
                </div>
            )}
            {!isTextInput && (
                <span
                    className={elemClassName}
                    onClick={onClickDetail}
                    onKeyDown={onClickDetail}
                    role="button"
                    tabIndex={index}
                    data-testid={dataTestId || ROOT_CLASS_NAME}
                >
                    {formatter(mainContent)}
                    {showEditIcon && (
                        <Icon
                            data-testid={
                                `${dataTestId}-icon` ||
                                `${ROOT_CLASS_NAME}-icon`
                            }
                            size={IconSizes.M}
                            className="editabletext-icon"
                            icon="edit"
                        />
                    )}
                </span>
            )}
        </>
    );
}

export default EditableText;
